import React, { Component } from "react";
import * as THREE from "three";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js";
import { DragControls, clampBoxInBox } from "./DragControls.js";
import Compressor from "compressorjs";
// import { ThisExpression } from "typescript";

// import { Scene as StarboardScene } from "./models/scene";
import {
  Configuration,
  ArtworkKind,
  FramedArtwork,
  FrameMaterial,
  CanvasEdgeFinish,
  CanvasArtwork,
  DibondEdgeFinish,
  DibondArtwork,
  PresentationMode,
} from "./models/configuration";

import ProductNode from "./ProductNode";
import CanvasNode from "./CanvasNode";
import DibondNode from "./DibondNode";
import FloatingCanvasNode from "./FloatingCanvasNode";
import FloatingDibondNode from "./FloatingDibondNode";
import FrameNode from "./FrameNode";
import BoxFrameNode from "./BoxFrameNode";
import PrintNode from "./PrintNode";
import ImageNode from "./ImageNode";
import { Vector3, Box2 } from "three";
import { lerp } from "three/src/math/MathUtils.js";
import { HoverControls } from "./HoverControls.js";
import { roughlyEquals } from "./helpers/Maths.js";
import * as MaterialHelper from "./helpers/MaterialHelper";

// import FSpyCameraLoader from "three-fspy-camera-loader";

interface StarboardProps {
  config: Configuration;
  captureMode: boolean;
  ultraHighResolution: boolean;
  allowDrag: boolean;
  allowHoverToPivot?: boolean;
  presentationMode?: PresentationMode;
  artworkPbrMaterials?: boolean;
  watermarkLevel: number;
  dynamicCanvasSize?: boolean;
  rootUrl?: string;
  onFinishLoading: any;
  contextWasLostAction: any; // TODO: is there no way to fix the resolve context issue internally to Starboard? e.g. https://developer.mozilla.org/en-US/docs/Web/API/Resize_Observer_API
}

class Starboard extends Component<StarboardProps> {
  mount: any;
  // loadedConfig?: Configuration;
  scene!: THREE.Scene;
  camera!: THREE.PerspectiveCamera;
  renderer!: THREE.WebGLRenderer;
  needsAnimation = true;
  skipAnimation = true; // Skip animation for first setting off positions/rotations...
  isFirstRender = true;
  backgroundPlane = new THREE.Mesh(
    new THREE.PlaneGeometry(1, 1),
    new THREE.MeshBasicMaterial()
  );
  productNode!: ProductNode;
  artworkTexture: THREE.Texture | undefined;
  readyForConfig = false;
  assetsLoaded = false;
  controls!: DragControls;
  hoverControls!: HoverControls;
  presentationMode?: PresentationMode;
  finishedLoadingBackgroundTexture = false;
  finishedLoadingArtworkTexture = false;
  finishedLoadingProductNode = false;
  safeArea: THREE.Box2 | undefined;
  safeAreaPlane!: THREE.Mesh<THREE.PlaneGeometry, THREE.MeshBasicMaterial>;

  // sphere: THREE.Mesh | undefined;

  sceneArtworkPosition: THREE.Vector3 = new THREE.Vector3(0, 0, 0);
  sceneArtworkRotation: THREE.Vector3 = new THREE.Vector3(0, 0, 0);

  targetArtworkPosition: THREE.Vector3 = new THREE.Vector3(0, 0, 0);
  targetArtworkRotation: THREE.Vector3 = new THREE.Vector3(0, 0, 0);

  currentArtworkRotation: THREE.Vector3 = new THREE.Vector3(0, 0, 0);

  isHovering = false;

  constructor(props: StarboardProps | Readonly<StarboardProps>) {
    super(props);
    this.state = {
      isLoadingProductNode: false,
      isLoadingArtwork: false,
      isLoadingScene: false,
    };
  }

  handleContextLost(event: any) {
    // console.log("context restored!");
    // parentThis.resizeCanvasToDisplaySize();
    this.props.contextWasLostAction();
  }

  async componentDidMount() {
    const width = this.mount.clientWidth; // TODO: see where this is set in resizeCanvasToDisplaySize too
    const height = this.mount.clientHeight;

    //ADD SCENE
    this.scene = new THREE.Scene();

    //ADD CAMERA
    this.camera = new THREE.PerspectiveCamera(27.4, width / height, 0.01, 100);
    this.scene.add(this.camera);
    this.camera.position.z = 4;

    //
    // Add HDRI
    //
    // Load a more "interesting" envMap when in artworkPbrMaterials mode so we get nice reflections
    let envMap = await new RGBELoader().loadAsync(
      `${this.props.rootUrl ?? ""}${
        this.props.artworkPbrMaterials
          ? "/textures/image0001.hdr" // "/textures/living-room-1-tiny.hdr"
          : "/textures/living-room-1-tiny-x5.hdr"
      }`
    );
    envMap.mapping = THREE.EquirectangularReflectionMapping;
    this.scene.background = null; //new THREE.Color(1, 1, 1);
    this.scene.environment = envMap;
    // this.scene.environmentIntensity = 0.9;
    //https://env.pmnd.rs

    //
    // Create renderer
    //
    if (this.renderer) {
      // Prevent duplicate canvases in dev mode...
      return;
    }
    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      preserveDrawingBuffer: true,
      alpha: true,
    });
    // this.renderer.outputEncoding = THREE.sRGBEncoding;
    this.renderer.outputColorSpace = THREE.SRGBColorSpace;
    if (this.props.dynamicCanvasSize) {
      this.renderer.setSize(width, height);
    } else {
      this.renderer.domElement.style.width = "100%";
      this.renderer.domElement.style.height = "100%";
      this.renderer.setSize(1024, 1024, false);
    }
    // this.renderer.setSize(width, height);
    this.renderer.setPixelRatio(window.devicePixelRatio); // Possibly want to turn this off if cloud rendering?
    this.mount.appendChild(this.renderer.domElement);
    // console.log("attaching to dom element");

    // Force scene re-render when WebGL context is restored
    // Context can be lost when returning to the browser after a long time, leaving a blank canvas
    // Note: At time of writing this has not been tested -> this never worked
    // this.renderer.domElement.addEventListener("webglcontextrestored", () => {
    //   this.renderScene();
    // });

    //
    // Add background plane
    //
    this.backgroundPlane.material.depthTest = true; // TODO: review implications of setting this to true...
    this.backgroundPlane.material.transparent = true;
    this.backgroundPlane.renderOrder = -3;
    this.camera.add(this.backgroundPlane);

    if (this.props.watermarkLevel > 0) {
      const watermarkPlane = new THREE.Mesh(
        new THREE.PlaneGeometry(1, 1),
        new THREE.MeshBasicMaterial()
      );
      watermarkPlane.material.depthTest = false;
      watermarkPlane.material.transparent = true;
      watermarkPlane.renderOrder = -3;
      let watermarkTexture = await new THREE.TextureLoader().loadAsync(
        this.props.watermarkLevel === 1
          ? "textures/watermark.png"
          : "textures/watermark-repeat.png"
      );
      watermarkPlane.material.map = watermarkTexture;
      watermarkPlane.material.needsUpdate = true;
      this.backgroundPlane.add(watermarkPlane);
    }

    //
    // Add safe area plane
    //
    this.safeAreaPlane = new THREE.Mesh(
      new THREE.PlaneGeometry(1, 1),
      new THREE.MeshBasicMaterial()
    );
    // this.safeAreaPlane.material.depthTest = true;
    this.safeAreaPlane.renderOrder = -2;
    this.safeAreaPlane.material.color = new THREE.Color(1, 1, 1);
    this.safeAreaPlane.material.transparent = false;
    this.safeAreaPlane.material.opacity = 0.3;
    this.safeAreaPlane.visible = false;
    this.scene.add(this.safeAreaPlane);

    //
    // Add drag controls
    //
    if (this.props.allowDrag || this.props.allowDrag === undefined) {
      this.controls = new DragControls(
        [],
        this.camera,
        this.renderer.domElement,
        this.safeArea,
        (isSelected: boolean) => {
          const showPlane = isSelected && !!this.safeArea;
          this.safeAreaPlane.material.transparent = showPlane;
          this.safeAreaPlane.visible = showPlane;
          this.safeAreaPlane.material.needsUpdate = true;
          this.renderScene();
        }
      );
      this.controls.addEventListener("drag", this.renderScene);
    }

    //
    // Add hover controls
    //
    if (this.props.allowHoverToPivot) {
      this.hoverControls = new HoverControls(
        [],
        this.camera,
        this.renderer.domElement,
        (hoverPosition: THREE.Vector2) => {
          // console.log("x: " + x);

          // Note: X and Y are flipped. This is because for horizontal mouse movements we want to rotate around the vertical axis
          // Note: Currently we don't really use vertical mouse position (except as a hack to determine hover presence...)
          // this.targetArtworkRotation.y = hoverPosition.x * 0.5; //-hoverPosition.y * 0.2;
          // this.targetArtworkRotation.x = -hoverPosition.y === 0 ? 0 : -0.05;

          this.isHovering = !(hoverPosition.x === 0 && hoverPosition.y === 0);

          let rotX = -hoverPosition.y === 0 ? 0 : -0.05;
          let rotY = -hoverPosition.x * 0.5; //-hoverPosition.y * 0.2;
          const hoverRotation = new THREE.Euler(rotX, rotY, 0, "XYZ");

          // console.log("___");
          // console.log(hoverRotation);
          let q1 = new THREE.Quaternion();
          q1.setFromEuler(
            new THREE.Euler(
              this.sceneArtworkRotation.x,
              this.sceneArtworkRotation.y,
              this.sceneArtworkRotation.z,
              "XYZ"
            )
          );
          let q2 = new THREE.Quaternion();
          q2.setFromEuler(hoverRotation);
          let q = q1.multiply(q2);
          // console.log(q);
          const newTargetRot = new THREE.Euler(0, 0, 0, "YXZ");
          newTargetRot.setFromQuaternion(q);
          // console.log(newTargetRot);

          let n = new Vector3(newTargetRot.x, newTargetRot.y, newTargetRot.z);
          // n.applyEuler(newTargetRot);
          this.targetArtworkRotation = n;

          // console.log(`${hoverPosition.x} - ${hoverPosition.y}`);

          // this.scene.environmentRotation = this.camera.rotation.clone();
          // this.scene.environmentRotation.y += 3.1415 * 2 * hoverPosition.x;
          // this.scene.environmentRotation.x += 3.1415 * 2 * hoverPosition.y;
          // // Mark all materials in the scene as needing update so environment rotation takes effect
          // this.scene.traverse(function (object: any) {
          //   if (object.material) {
          //     object.material.needsUpdate = true;
          //   }
          // });

          this.needsAnimation = true;
        }
      );
      // this.controls.addEventListener("drag", this.renderScene);
    }

    // this.sphere = new THREE.Mesh(
    //   new THREE.SphereGeometry(0.1),
    //   new THREE.MeshStandardMaterial({ roughness: 0.0, metalness: 1.0 })
    // );
    // this.camera.add(this.sphere);
    // this.sphere?.position.set(0, 0, -2);

    //
    // Debug grid
    //
    // let debugGrid = new THREE.GridHelper();
    // debugGrid = new THREE.GridHelper(10, 20, new THREE.Color(1, 0, 0));
    // debugGrid.rotation.x = Math.PI / 2;
    // this.scene.add(debugGrid);

    // console.log("Reached end of componentDidMount");
    this.readyForConfig = true;

    this.renderer.domElement.addEventListener(
      "webglcontextlost",
      this.handleContextLost.bind(this)
    );

    window.addEventListener(
      "resize",
      this.resizeCanvasToDisplaySize.bind(this)
    );
    this.resizeCanvasToDisplaySize();

    this.start();
  }
  async componentDidUpdate(prevProps: StarboardProps) {
    // console.log("componentDidUpdate:");
    // console.log(this.props.config);

    if (prevProps.captureMode !== this.props.captureMode) {
      this.updateCanvasSize(this.props.captureMode);
    }

    const prevPresentationMode = this.presentationMode;
    this.presentationMode =
      this.props.presentationMode ??
      this.props.config.defaultPresentationMode ??
      PresentationMode.RoomView;

    if (prevPresentationMode !== this.presentationMode) {
      this.needsAnimation = true;
    }

    if (!prevProps || prevProps.config !== this.props.config) {
      // console.log("componentDidUpdate -> loadConfig");
      await this.loadConfig(prevProps.config);
    }
  }

  async loadConfig(prevConfig: Configuration | null) {
    if (!this.props.config.scene) {
      return;
    }

    const thisConfig = this.props.config;

    this.assetsLoaded = false;

    //
    // Background texture
    //
    if (
      !prevConfig ||
      prevConfig.scene?.backgroundTexture !==
        this.props.config.scene.backgroundTexture
    ) {
      this.finishedLoadingBackgroundTexture = false;
      this.setState({ isLoadingScene: true });
      let backgroundTexture = await new THREE.TextureLoader().loadAsync(
        this.props.config.scene.backgroundTexture
      );
      backgroundTexture.colorSpace = THREE.SRGBColorSpace;
      if (
        thisConfig.scene.backgroundTexture !==
        this.props.config.scene.backgroundTexture
      ) {
        // console.log("Race condition averted");
        // TODO: loadConfig needs revisiting for race conditions. also should cancel downloads if no longer needed.
        return;
      }
      this.backgroundPlane.material.map = backgroundTexture;
      // this.backgroundPlane.material.color = new THREE.Color("#ccc");
      this.backgroundPlane.material.needsUpdate = true;
      this.finishedLoadingBackgroundTexture = true;
      this.skipAnimation = true;
    }

    let shouldKeepProductPosition = false;
    if (prevConfig && prevConfig.scene?.id === this.props.config.scene.id) {
      shouldKeepProductPosition = true;
    }

    //
    // Load product node
    //
    let previousProductPosition =
      this.productNode?.position ?? new Vector3(0, 0, 0);
    // Don't reload product node unnecessarily
    if (
      !prevConfig ||
      this.props.config.artwork.kind !== prevConfig?.artwork?.kind
    ) {
      this.finishedLoadingProductNode = false;
      if (this.productNode) {
        this.productNode.removeFromParent();
      }
      if (this.props.config.artwork.kind === ArtworkKind.Framed) {
        this.productNode = new FrameNode();
      } else if (this.props.config.artwork.kind === ArtworkKind.Canvas) {
        this.productNode = new CanvasNode();
      } else if (this.props.config.artwork.kind === ArtworkKind.Dibond) {
        this.productNode = new DibondNode();
      } else if (
        this.props.config.artwork.kind === ArtworkKind.FloatingCanvas
      ) {
        this.productNode = new FloatingCanvasNode();
      } else if (
        this.props.config.artwork.kind === ArtworkKind.FloatingDibond
      ) {
        this.productNode = new FloatingDibondNode();
      } else if (this.props.config.artwork.kind === ArtworkKind.BoxFrame) {
        this.productNode = new BoxFrameNode();
      } else if (this.props.config.artwork.kind === ArtworkKind.Print) {
        this.productNode = new PrintNode();
      } else {
        this.productNode = new ImageNode();
      }
      this.setState({ isLoadingProductNode: true });

      this.productNode.rootUrl = this.props.rootUrl;
      this.productNode.usePbrMaterials =
        this.props.artworkPbrMaterials ?? false;

      // console.log("start productNode.setup()");
      await this.productNode.setup();
      // console.log("finish productNode.setup()");
      this.finishedLoadingProductNode = true;
      this.skipAnimation = true;
    }

    if (this.productNode == null) {
      return;
    }

    if (shouldKeepProductPosition && previousProductPosition) {
      console.log("shouldKeepProductPosition");
      this.productNode.position.copy(previousProductPosition);
      // this.targetArtworkPosition = previousProductPosition;
    } else {
      // console.log("setting artwork position");

      // console.log(this.sceneArtworkPosition);
      // console.log(this.props.config.scene.artworkPosition);
      // if (
      //   !(
      //     this.sceneArtworkPosition.x ===
      //       this.props.config.scene.artworkPosition.x &&
      //     this.sceneArtworkPosition.y ===
      //       this.props.config.scene.artworkPosition.y &&
      //     this.sceneArtworkPosition.z ===
      //       this.props.config.scene.artworkPosition.z
      //   )
      // ) {
      //   console.log("skip next animation");
      //   this.isFirstRender = true;
      // }

      this.sceneArtworkPosition = this.props.config.scene.artworkPosition
        ? new Vector3(
            this.props.config.scene.artworkPosition.x,
            this.props.config.scene.artworkPosition.y,
            this.props.config.scene.artworkPosition.z
          )
        : new Vector3(0, 0, 0);
      // if (this.props.config.presentationMode === PresentationMode.CloseUp) {
      //   console.log("it's close up mode yeap");
      //   // this.rootNode.rotateY(this.props.config.scene.cameraRotation.y);
      //   this.sceneArtworkPosition = this.getCloseUpArtworkPosition();
      //   this.productNode.position.copy(this.sceneArtworkPosition);
      //   // this.rootNode.rotateY(this.props.config.scene.cameraRotation.y);
      // }
      // this.productNode.position.copy(artworkPosition);
      this.targetArtworkPosition = this.sceneArtworkPosition.clone();
      this.needsAnimation = true;
    }
    this.sceneArtworkRotation = this.props.config.scene.artworkRotation
      ? new Vector3(
          this.props.config.scene.artworkRotation.x, //  + 3.1415 / 2
          this.props.config.scene.artworkRotation.y, //  + 3.1415 / 2
          this.props.config.scene.artworkRotation.z
        )
      : new Vector3(0, 0, 0);
    this.targetArtworkRotation = this.sceneArtworkRotation.clone();
    // console.log(this.sceneArtworkRotation);
    this.productNode.rotation.set(
      this.sceneArtworkRotation.x,
      this.sceneArtworkRotation.y,
      this.sceneArtworkRotation.z,
      "ZYX"
    );
    this.controls?.artworkPosition.copy(this.productNode.position); // TODO: consider how this impacts targetArtworkPosition stuff, also sceneArtworkPosition etc
    this.controls?.artworkRotation.copy(this.productNode.rotation);
    this.controls?.updatePlane(this.controls);

    // Configure product
    this.productNode.isBordered = this.props.config.artwork.isBordered;
    if (this.props.config.artwork.kind === ArtworkKind.Framed) {
      let framedArtwork = this.props.config.artwork as FramedArtwork;
      let frameNode = this.productNode as FrameNode;

      await MaterialHelper.applyPreset(
        frameNode.frameMaterial,
        framedArtwork.frameMaterial,
        this.props.rootUrl ?? ""
      );
      if (frameNode.isMounted !== framedArtwork.isMounted) {
        this.skipAnimation = true;
      }
      frameNode.isMounted = framedArtwork.isMounted;
    } else if (this.props.config.artwork.kind === ArtworkKind.BoxFrame) {
      let framedArtwork = this.props.config.artwork as FramedArtwork;
      let boxFrameNode = this.productNode as BoxFrameNode;

      await MaterialHelper.applyPreset(
        boxFrameNode.frameMaterial,
        framedArtwork.frameMaterial,
        this.props.rootUrl ?? ""
      );
    } else if (this.props.config.artwork.kind === ArtworkKind.FloatingCanvas) {
      let framedArtwork = this.props.config.artwork as FramedArtwork;
      let frameNode = this.productNode as FloatingCanvasNode;

      await MaterialHelper.applyPreset(
        frameNode.frameMaterial,
        framedArtwork.frameMaterial,
        this.props.rootUrl ?? ""
      );
    } else if (this.props.config.artwork.kind === ArtworkKind.FloatingDibond) {
      let framedArtwork = this.props.config.artwork as FramedArtwork;
      let frameNode = this.productNode as FloatingDibondNode;

      await MaterialHelper.applyPreset(
        frameNode.frameMaterial,
        framedArtwork.frameMaterial,
        this.props.rootUrl ?? ""
      );
    } else if (this.props.config.artwork.kind === ArtworkKind.Canvas) {
      // Any canvas-specific config to go here
      let canvasArtwork = this.props.config.artwork as CanvasArtwork;
      let canvasNode = this.productNode as CanvasNode;

      switch (canvasArtwork.edgeFinish) {
        case CanvasEdgeFinish.White: {
          canvasNode.edgeMaterial.color = new THREE.Color(
            "#e2e2e2"
          ).convertLinearToSRGB();
          break;
        }
        case CanvasEdgeFinish.Black: {
          canvasNode.edgeMaterial.color = new THREE.Color(
            "#0f0f0f"
          ).convertLinearToSRGB();
          break;
        }
      }
      canvasNode.edgeMaterial.needsUpdate = true;
    } else if (this.props.config.artwork.kind === ArtworkKind.Dibond) {
      // Any canvas-specific config to go here
      let dibondArtwork = this.props.config.artwork as DibondArtwork;
      let dibondNode = this.productNode as DibondNode;

      switch (dibondArtwork.edgeFinish) {
        case DibondEdgeFinish.White: {
          dibondNode.edgeMaterial.color = new THREE.Color(
            "#e2e2e2"
          ).convertLinearToSRGB();
          break;
        }
        case DibondEdgeFinish.Black: {
          dibondNode.edgeMaterial.color = new THREE.Color(
            "#0f0f0f"
          ).convertLinearToSRGB();
          break;
        }
      }
      dibondNode.edgeMaterial.needsUpdate = true;
    } else {
      // Any print-specific config to go here
    }

    // Update drag controller
    if (this.controls) {
      this.controls.objects = [this.productNode];
    }

    //
    // Artwork
    //
    // - Texture
    if (this.props.config.artwork.imagePath) {
      if (
        !prevConfig ||
        prevConfig.artwork?.imagePath !== this.props.config.artwork.imagePath
      ) {
        this.finishedLoadingArtworkTexture = false;
        this.setState({ isLoadingArtwork: true });

        // Fetch image
        let fetchedImage = await fetch(this.props.config.artwork.imagePath);
        let imageBlob = await fetchedImage.blob();
        // Run through Compressor... as a crude way to normalise the image's colour space
        const result = await new Promise((resolve, reject) => {
          new Compressor(imageBlob, {
            success: resolve,
            error: reject,
          });
        });
        let imageData = await blobToData(result as any);

        // Load texture
        this.artworkTexture = await new THREE.TextureLoader().loadAsync(
          imageData as any
        );
        this.artworkTexture.colorSpace = THREE.SRGBColorSpace;

        this.finishedLoadingArtworkTexture = true;
      }
    } else if (this.props.config.artwork.imageSource) {
      if (
        !prevConfig ||
        prevConfig.artwork?.imageSource !==
          this.props.config.artwork.imageSource
      ) {
        this.finishedLoadingArtworkTexture = false;
        let image = new Image();
        image.src = this.props.config.artwork.imageSource;
        this.setState({ isLoadingArtwork: true });
        await image.decode();
        this.artworkTexture = new THREE.Texture();
        this.artworkTexture.image = image;
        this.artworkTexture.magFilter = THREE.LinearFilter;
        this.artworkTexture.minFilter = THREE.LinearMipmapLinearFilter;
        this.artworkTexture.colorSpace = THREE.SRGBColorSpace;

        this.artworkTexture.needsUpdate = true;
        this.finishedLoadingArtworkTexture = true;
      }
    } else {
      this.artworkTexture = undefined;
    }
    if (
      this.productNode.artworkMaterial &&
      this.productNode.artworkMaterial!.map !== this.artworkTexture
    ) {
      this.productNode.artworkMaterial!.map = this.artworkTexture || null;
      this.productNode.artworkMaterial!.needsUpdate = true;
    }
    // TODO: remove this prototype code block
    if (this.productNode.artworkMaterial) {
      if (
        this.props.config.scene.id === "bedroom-1" ||
        this.props.config.scene.id === "bedroom-2"
      ) {
        this.productNode.artworkMaterial!.color = new THREE.Color(
          1,
          1,
          1
        ).multiplyScalar(0.9);
      } else {
        this.productNode.artworkMaterial!.color = new THREE.Color(1, 1, 1);
      }
    }

    if (this.productNode.artworkMaterial?.map) {
      this.productNode.applyTextureContentModeTransformAndBorderShaderModifier(
        this.productNode.artworkMaterial?.map,
        this.props.config.artwork.size,
        this.productNode.isBordered
          ? new THREE.Vector2(0.05, 0.05)
          : new THREE.Vector2(0, 0),
        this.props.config.artwork.contentMode
      );
    }

    // TODO: Need a proper way to handle what we show when we don't have an artwork image, e.g. placeholder, loading indicator, error message
    if (
      this.props.config.artwork.imageSource ||
      this.props.config.artwork.imagePath
    ) {
      this.scene.add(this.productNode);
    }

    // Flip product's artificial shadow if scene requires it
    this.productNode.flipShadow = this.props.config.scene.flipShadow;

    if (
      !prevConfig?.artwork ||
      !(
        prevConfig.artwork.size.x === this.props.config.artwork.size.x &&
        prevConfig.artwork.size.y === this.props.config.artwork.size.y
      )
    ) {
      this.skipAnimation = true;
      // Note: we skip all animation when the artwork size changes. this could be improved... we could only skip size change animations. but I think let's keep it simpler for now.
    }
    this.productNode.setDimensions(
      this.props.config.artwork.size.x,
      this.props.config.artwork.size.y
    );

    this.updateCanvasSize();

    //
    // Camera
    //
    let cameraPositionZ = this.props.config.scene.cameraPosition.z;
    if (this.props.config.scene.artworkOnlyMode) {
      // Warning: this artworkOnlyMode code is not canvas aspect ratio aware. e.g. we assume same horizontal and vertical FOV.
      // Warning: requires scene config to have camera and artwork at zero pos and rot
      const artworkOnlyMode_artworkProportionOfCanvas =
        this.props.config.scene.artworkOnlyCanvasFillRatio ?? 0.5;
      const distance =
        Math.max(
          this.productNode.totalSize().x,
          this.productNode.totalSize().y
        ) /
        artworkOnlyMode_artworkProportionOfCanvas /
        2 /
        Math.tan((this.props.config.scene.cameraFov / 2) * (Math.PI / 180));
      cameraPositionZ = distance;
    }
    this.camera.position.set(
      this.props.config.scene.cameraPosition.x,
      this.props.config.scene.cameraPosition.y,
      cameraPositionZ
    );
    this.camera.rotation.set(
      this.props.config.scene.cameraRotation.x,
      this.props.config.scene.cameraRotation.y,
      this.props.config.scene.cameraRotation.z,
      "ZYX"
      // "XZY" // think this is not needed now I'm exporting the rotation in the appropriate order
    );
    const sceneNeedsUpdate = !this.scene.environmentRotation.equals(
      this.camera.rotation
    );
    if (this.props.artworkPbrMaterials) {
      this.scene.environmentRotation = this.camera.rotation.clone();
      this.scene.environmentRotation.y += 3.1415 * 2 * 0.131; //0.13096267692795172 - 0.05563210124578588
      this.scene.environmentRotation.x += 3.1415 * 2 * 0.056;
    }
    // Mark all materials in the scene as needing update so environment rotation takes effect
    if (sceneNeedsUpdate) {
      this.scene.traverse(function (object: any) {
        if (object.material) {
          object.material.needsUpdate = true;
        }
      });
    }

    // Rotate root node as workaround for rotating envMap
    // this.rootNode.rotation.y = -this.props.config.scene.cameraRotation.y;

    if (this.props.config.scene.cameraFov) {
      this.camera.fov = this.props.config.scene.cameraFov; // TODO: will need to convert between Blender (width based) and Three.js (height based) FOVs once we support non-square scenes.
    } else {
      this.camera.fov = 27.4; // TODO: maybe don't have a default... but throw an error
    }
    this.camera.updateProjectionMatrix();

    //
    // Update background plane to fill the far frustum
    //
    const fovRad = (this.camera.fov / 180) * Math.PI;
    const h = Math.tan(fovRad / 2) * this.camera.far * 2;
    const w = h * this.camera.aspect;
    const d = this.camera.far;
    this.backgroundPlane.scale.set(w, h, 1);
    this.backgroundPlane.position.setZ(-d);

    if (this.props.config.scene.safeArea) {
      const safeArea = new THREE.Box2();
      // console.log(this.props.config.scene.safeArea);
      safeArea.setFromPoints([
        new THREE.Vector2(
          this.props.config.scene.safeArea.min.x,
          this.props.config.scene.safeArea.min.y
        ),
        new THREE.Vector2(
          this.props.config.scene.safeArea.max.x,
          this.props.config.scene.safeArea.max.y
        ),
      ]);
      this.safeArea = safeArea;

      let safeAreaCenter = new THREE.Vector2(0, 0);
      safeArea.getCenter(safeAreaCenter);

      let safeAreaSize = new THREE.Vector2(0, 0);
      safeArea.getSize(safeAreaSize);

      this.safeAreaPlane.position.set(safeAreaCenter.x, safeAreaCenter.y, 0);
      this.safeAreaPlane.scale.set(safeAreaSize.x, safeAreaSize.y, 1);

      // Enforce bounds
      const productBounds = new Box2();
      productBounds.setFromCenterAndSize(
        new THREE.Vector2(
          this.productNode.position.x,
          this.productNode.position.y
        ),
        this.productNode.totalSize()
      );

      clampBoxInBox(productBounds, this.safeArea);
      let newCenter = new THREE.Vector2();
      productBounds.getCenter(newCenter);
      this.productNode.position.copy(
        // TODO: consider how this impacts targetArtworkPosition stuff
        new THREE.Vector3(newCenter.x, newCenter.y, 0)
      );
    } else {
      this.safeArea = undefined;
    }
    if (this.controls) {
      this.controls.safeArea = this.safeArea;
    }

    // console.log("h:");
    // console.log(h);
    // console.log("d:");
    // console.log(d);

    // let loader = new FSpyCameraLoader();
    // loader.load(
    //   "cafeSquareFspy.json",
    //   // onload
    //   (result) => {
    //     console.log("pos: " + JSON.stringify(result.position));
    //     console.log("rot: " + JSON.stringify(result.rotation.toVector3()));
    //     // this.camera = result as any;
    //   },
    //   // onprogress
    //   function (xhr) {
    //     //console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
    //   },
    //   // onerror
    //   function (error) {
    //     console.log("ERROR");
    //   }
    // );

    // console.log("Bottom of loadConfig reached, this is the current config:");
    // console.log(this.props.config);

    if (this.finishedLoadingBackgroundTexture) {
      this.setState({ isLoadingScene: false });
    }
    if (this.finishedLoadingArtworkTexture) {
      this.setState({ isLoadingArtwork: false });
    }
    if (this.finishedLoadingProductNode) {
      this.setState({ isLoadingProductNode: false });
    }

    // TODO: add finishedLoadingProductNode into this logic check too...
    // TODO: what if the texture doesn't need to change though? e.g. just changing artwork size
    // This is a super crude way to determine when external assets have finished downloading. Needs re-architecting but probably does the job for the bulk rendering MVP.
    // console.log(
    //   "this.props.config.artwork.imageSource: " +
    //     this.props.config.artwork.imageSource
    // );
    // console.log(
    //   "this.props.config.artwork.imagePath: " +
    //     this.props.config.artwork.imagePath
    // );
    // console.log("isLoadingScene: " + (this.state as any).isLoadingScene);
    // console.log("isLoadingArtwork: " + (this.state as any).isLoadingArtwork);
    // console.log(
    //   "isLoadingProductNode: " + (this.state as any).isLoadingProductNode
    // );
    // console.log(
    //   "finishedLoadingBackgroundTexture: " +
    //     this.finishedLoadingBackgroundTexture
    // );
    // console.log(
    //   "finishedLoadingArtworkTexture: " + this.finishedLoadingArtworkTexture
    // );
    // console.log(
    //   "finishedLoadingProductNode: " + this.finishedLoadingProductNode
    // );
    if (
      (this.props.config.artwork.imageSource ||
        this.props.config.artwork.imagePath) &&
      (!(this.state as any).isLoadingArtwork ||
        this.finishedLoadingArtworkTexture) &&
      (!(this.state as any).isLoadingScene ||
        this.finishedLoadingBackgroundTexture) &&
      (!(this.state as any).isLoadingProductNode ||
        this.finishedLoadingProductNode) &&
      (this.finishedLoadingBackgroundTexture ||
        this.finishedLoadingArtworkTexture ||
        this.finishedLoadingProductNode)
    ) {
      // console.log("Artwork and scene textures have been loaded");

      // console.log("Assets loaded");
      this.assetsLoaded = true;
    }

    this.renderScene();

    if (this.props.onFinishLoading && this.assetsLoaded) {
      this.props.onFinishLoading();
    }

    this.needsAnimation = true; // Needed so when you change artwork size the close up mode updates bounds (with animation)

    // this.loadedConfig = this.props.config;

    // }
  }
  componentWillUnmount() {
    // console.log("removing domElement and event listeners");
    window.removeEventListener(
      "resize",
      this.resizeCanvasToDisplaySize.bind(this)
    );
    if (this.renderer?.domElement) {
      this.renderer.domElement.removeEventListener(
        "webglcontextlost",
        this.handleContextLost.bind(this),
        false
      );
      this.mount.removeChild(this.renderer.domElement);
    }
  }
  start = () => {
    // console.log("start -> loadConfig");
    this.loadConfig(null);
  };
  renderScene = () => {
    // this.resizeCanvasToDisplaySize();
    if (this.renderer) {
      this.renderer.render(this.scene, this.camera);

      // console.log("Context:");
      // console.log(this.renderer.getContext().isContextLost());

      // console.log("Rendering scene:" + this.renderer);
      if (this.assetsLoaded) {
        // console.log("window.status = 'ready'");
        window.status = "ready";
      }
    }

    const clock = new THREE.Clock();
    // TODO: how often is this being re-assigned..?
    if (this.renderer) {
      this.renderer.setAnimationLoop(() => {
        const lerpDelta = 1 - Math.pow(2, -9 * clock.getDelta()); //0.05

        if (this.needsAnimation && this.productNode) {
          this.needsAnimation = false;

          // Rotate root node temporarily while we do these calculations. This is a workaround for rotating the envMap.
          // this.rootNode.rotateY(this.props.config.scene.cameraRotation.y);

          // const lerpDelta =
          //   1 - Math.pow(2, -9 * Math.min(clock.getDelta(), 1 / 15)); // Variable frame rate but assume minimum 15fps to avoid lerp jumps when resuming rendering

          let newProductPosition: Vector3;
          let newBackgroundPlaneOpacity: number;
          let newArtworkRotation: Vector3;

          // was doing loadedConfig instead at one point...
          if (this.presentationMode === PresentationMode.CloseUp) {
            // console.log("Applying close up position");

            newProductPosition = this.getCloseUpArtworkPosition();

            newBackgroundPlaneOpacity = 0.0;

            // Alternative quaternion-based lerp, not needed currently:
            // const qStart = new THREE.Quaternion().setFromEuler(
            //   new THREE.Euler().setFromVector3(
            //     this.currentArtworkRotation,
            //     "XYZ"
            //   )
            // );
            // const qEnd = new THREE.Quaternion().setFromEuler(
            //   new THREE.Euler().setFromVector3(
            //     this.targetArtworkRotation,
            //     "XYZ"
            //   )
            // );
            // const qCurrent = new THREE.Quaternion().slerpQuaternions(
            //   qStart,
            //   qEnd,
            //   this.skipAnimation ? 1 : lerpDelta * 2
            // );
            // this.currentArtworkRotation = new THREE.Vector3().setFromEuler(
            //   new THREE.Euler().setFromQuaternion(qCurrent)
            // );
            this.currentArtworkRotation.lerp(
              this.targetArtworkRotation,
              this.skipAnimation ? 1 : lerpDelta * 2
            ); // was 0.1

            // TODO: Does this make sense? Shouldn't this check if currentArtworkRotation matches the actual rotation on the product?
            if (
              !roughlyEquals(
                this.targetArtworkRotation,
                this.currentArtworkRotation
              )
            ) {
              this.needsAnimation = true;
            }

            newArtworkRotation = new THREE.Vector3(
              this.currentArtworkRotation.x,
              this.currentArtworkRotation.y,
              this.currentArtworkRotation.z
            );

            // (this.props.config as any).presentationPivot ?? 0;
          } else {
            // console.log("Applying standard position");
            newProductPosition = this.targetArtworkPosition;
            newBackgroundPlaneOpacity = 1.0;

            newArtworkRotation = new THREE.Vector3(
              this.sceneArtworkRotation.x,
              this.sceneArtworkRotation.y,
              this.sceneArtworkRotation.z
            );
            this.currentArtworkRotation = newArtworkRotation.clone();

            // console.log(newProductPosition);
          }

          // console.log(newProductPosition);

          // console.log(this.props.config);
          // console.log(newArtworkRotation);

          if (this.productNode) {
            this.productNode.position.lerp(
              newProductPosition,
              this.skipAnimation ? 1 : lerpDelta
            );

            // this.productNode.setRotationFromAxisAngle(
            //   new Vector3(0, 1, 0),
            //   newArtworkRotation.x
            // );
            // this.productNode.rotateOnAxis(
            //   new Vector3(1, 0, 0),
            //   newArtworkRotation.y
            // );

            // console.log("setting newArtworkRotation:");
            // console.log(newArtworkRotation);
            this.productNode.rotation.set(
              newArtworkRotation.x,
              newArtworkRotation.y,
              newArtworkRotation.z,
              "ZYX"
            );

            // this.productNode.setRotationFromAxisAngle(
            //   new Vector3(0, 1, 0),
            //   newArtworkRotation.x
            // );
            // this.productNode.rotateOnAxis(
            //   new Vector3(1, 0, 0),
            //   newArtworkRotation.y
            // );

            // TODO: for completeness should also do roughlyEquals test on rotation

            if (!roughlyEquals(this.productNode.position, newProductPosition)) {
              this.needsAnimation = true;
            }
          }
          this.backgroundPlane.material.opacity = lerp(
            this.backgroundPlane.material.opacity,
            newBackgroundPlaneOpacity,
            this.skipAnimation ? 1 : lerpDelta
          );
          if (
            !roughlyEquals(
              newBackgroundPlaneOpacity,
              this.backgroundPlane.material.opacity
            )
          ) {
            this.needsAnimation = true;
          }

          // this.rootNode.rotateY(-this.props.config.scene.cameraRotation.y);
          this.renderer.render(this.scene, this.camera);

          this.isFirstRender = false;
          this.skipAnimation = false;
        }
      });
    }
  };

  timeout(delay: number) {
    return new Promise((res) => setTimeout(res, delay));
  }

  resizeCanvasToDisplaySize = async () => {
    // console.log("RESIZING");

    if (this.mount) {
      // Artificial delay is needed here because otherwise the dimensions can be out of date and so the canvas doesn't fill the frame properly.
      // TODO: is there a way to respond to parent div size change rather than the window? maybe: https://developer.mozilla.org/en-US/docs/Web/API/Resize_Observer_API
      await this.timeout(1);

      this.updateCanvasSize();
    }
  };

  updateCanvasSize = (captureMode: boolean = false) => {
    // console.log("mount:");
    // console.log(this.mount);
    const clientWidth = this.mount?.clientWidth ?? 0;
    const clientHeight = this.mount?.clientHeight ?? 0;

    let width;
    let height;
    let aspectRatio = this.props.config.scene.aspectRatio ?? 1;
    // console.log("Aspect ratio: " + aspectRatio);
    let longestEdge;
    if (captureMode) {
      const positiveAspectRatio =
        aspectRatio > 1 ? aspectRatio : 1 / aspectRatio;
      longestEdge = 1024 * (1 + (positiveAspectRatio - 1) / 2);
    } else {
      longestEdge = Math.max(clientWidth, clientHeight);
    }
    if (aspectRatio > 1.0) {
      width = longestEdge;
      height = longestEdge / aspectRatio;
    } else {
      width = longestEdge * aspectRatio;
      height = longestEdge;
    }

    if (width > clientWidth) {
      height = (height / width) * clientWidth;
      width = clientWidth;
    } else if (height > clientHeight) {
      width = (width / height) * clientHeight;
      height = clientHeight;
    }

    this.setCanvasSize(
      width,
      height,
      captureMode
        ? this.props.ultraHighResolution
          ? 4
          : 2
        : window.devicePixelRatio
    );
  };

  setCanvasSize = (width: number, height: number, pixelRatio: number) => {
    // console.log(`Setting canvas size: ${width} x ${height}`);

    if (this.props.dynamicCanvasSize) {
      this.renderer?.setSize(width, height, true); // TODO: Could just render at say 1024x1024 and rely on div being scaled down, using with "aspect-ratio: ???" to ensure it matches the scene?
    }
    this.renderer?.setPixelRatio(pixelRatio);

    // TODO: I think this shouldn't be necessary... in theory... because the aspect ratio should be kept constant by the React page that integrates Starboard.
    // TODO: However... maybe when the page loads the canvas is being set to "100%" width and height but the parent hasn't had time to set up yet... or something...
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();

    // console.log("setCanvasSize calling renderScene: " + width);
    this.renderScene(); // TODO: consider if we can make less calls to this to boost performance. if you disable it entirely then you don't get the best resolution for the size, it's scaled.
    // Re the above, I've now hardcoded the canvas resolution so this isn't needed... should review this idea though... -> actually it is needed... I've observed on jonah allen... not sure why. oh maybe because the canvas div is being removed then re-added? so it needs to be re-rendered?
  };

  getCloseUpArtworkPosition = () => {
    const cameraForwards = new THREE.Vector3(); // TODO: reuse rather than recreating all the time
    this.camera.getWorldDirection(cameraForwards);

    const artworkOnlyMode_artworkProportionOfCanvas = 0.7;
    const productTotalSize = this.productNode.totalSize();
    let distance =
      Math.max(productTotalSize.x, productTotalSize.y) /
      artworkOnlyMode_artworkProportionOfCanvas /
      2 /
      Math.tan((this.props.config.scene.cameraFov / 2) * (Math.PI / 180));

    // Zoom in slightly if we're hovering (this uses a hacky way to detect that we're hovering though)
    if (this.isHovering) {
      distance *= 0.95;
    }

    return this.camera.position
      .clone()
      .addScaledVector(cameraForwards, distance)
      .addScaledVector(new THREE.Vector3(0, 1, 0), 0.07 * productTotalSize.y); // Lift artwork up a little bit to make space for the "view in a room" button
  };

  render() {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          alignContent: "center",
          justifyContent: "center",
          opacity:
            (this.state as any).isLoadingArtwork ||
            (this.state as any).isLoadingScene ||
            this.props.config.artwork?.imageSource === null
              ? 0.35
              : 1.0,
        }}
        ref={(mount) => {
          this.mount = mount;
        }}
      ></div>
    );
  }
}

const blobToData = (blob: Blob) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

export default React.memo(Starboard);
